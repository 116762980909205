import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PolicyView from '../views/Policy.vue'
import TermsView from '../views/Terms.vue'
import SupportView from '../views/Support.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/policy',
    name: 'policy-view',
    component: PolicyView
  },
  {
    path: '/support',
    name: 'support-view',
    component: SupportView
  },
  {
    path: '/terms',
    name: 'terms-view',
    component: TermsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
