<template>
  <div class="text-center">
    <!-- <img class="img-fluid" src="../assets/pebblelanding.png" alt=""> -->
    <!-- Image Map Generated by http://www.image-map.net/ -->
    <img
      class="img-fluid"
      src="../assets/pebblelanding.png"
      usemap="#image-map"
    />

    <map name="image-map">
      <area
        target="_blank"
        alt="Google play store Top"
        title="Google play store Top"
        href="https://play.google.com/store/apps/details?id=com.scripta55.pebble.pebble"
        coords="439,429,620,484"
        shape="rect"
      />
      <area
        target="_blank"
        alt="Google play store bottom"
        title="Google play store bottom"
        href="https://play.google.com/store/apps/details?id=com.scripta55.pebble.pebble"
        coords="732,2832,919,2770"
        shape="rect"
      />
      <area
        target="_blank"
        alt="Apple bottom"
        title="Apple bottom"
        href="https://apps.apple.com/app/id6450679626?platform=iphone"
        coords="517,2777,705,2832"
        shape="rect"
      />
      <area
        target="_self"
        alt="Privacy"
        title="Privacy"
        href="https://pebblerelationshipapp.com/policy"
        coords="585,2964,467,2929"
        shape="rect"
      />
      <area
        target="_self"
        alt="Terms"
        title="Terms"
        href="https://pebblerelationshipapp.com/terms"
        coords="297,2934,447,2962"
        shape="rect"
      />
      <area
        target="_blank"
        alt="apple middle"
        title="apple middle"
        href="https://apps.apple.com/app/id6450679626?platform=iphone"
        coords="1021,1149,835,1086"
        shape="rect"
      />
      <area
        target="_blank"
        alt="google middle "
        title="google middle "
        href="https://play.google.com/store/apps/details?id=com.scripta55.pebble.pebble"
        coords="1234,1147,1038,1080"
        shape="rect"
      />
      <area
        target="_blank"
        alt="apple top"
        title="apple top"
        href="https://apps.apple.com/app/id6450679626?platform=iphone"
        coords="226,431,410,485"
        shape="rect"
      />
    </map>
  </div>
</template>

<script>
export default {
  name: 'HomeView'
}</script>
