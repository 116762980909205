<template>
  <div class="about container">

    <!-- <button
      class="btn btn-primary"
      data-bs-target="#collapseTarget"
      data-bs-toggle="collapse"
    >
      Bootstrap collapse
    </button> -->
    <div class=" py-2" id="">
      <h1>Support</h1>
      <p>If you have any issues or suggestions please email or call us: </p>
      <a href="mailto:support@pebblerelationshipapp.com" target="_top"> support@pebblerelationshipapp.com</a>
      <p>Phone: +31 68 47 555 21</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'support-view'
}
</script>

<style>

</style>
